import {
  Link
} from "react-router-dom";
import Moment from 'react-moment';

//stylesheets
import './LandingFeaturedDiv.css';

export default function LandingFeaturedDiv (props) {

  const { storyTitle, storyAuthor, publishDate, storyDescription, storyLink, storyThumbnail, ctaLink, storyType } = props.featInfo;

  const { url } = storyThumbnail.fields.file;

  return (
    <div className="featlink-div">

      <div className="featlink-graphic">
        <img src={url} alt="Story Thumbnail" />
      </div>

      <div className="featlink-storyinfo">
        <div className="featlink-storyhead-div">
          <h3 className="featlink-storyhead">{storyTitle}</h3>
        </div>
        <div className="featlink-storypar-div">
          <p className="featlink-storypar">{storyDescription}</p>
        </div>
      </div>

      <div className="featlink-cta-btn">
        <FeatIfLink storyLink={storyLink} storyType={storyType} ctaLink={ctaLink}/>
        <div className="featlink-cta-btn-otln"></div>
      </div>
    </div>
  )
}

function FeatIfLink(props) {

  const { storyLink, ctaLink, storyType } = props;

  if (ctaLink === 'Watch Now') {
    return (
      // eslint-disable-next-line
      <a href={`${storyLink}`} target="_blank">{ctaLink}</a>
    );
  } else if (ctaLink === 'Read Now') {
    return (
      // eslint-disable-next-line
      <a href={`${storyLink}`} target="_blank">{ctaLink}</a>
    );
  } return (
    <Link to={`/${storyType}/${storyLink}`}>{ctaLink}</Link>
  )
}
