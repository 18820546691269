import React, { Component } from 'react';
import {
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { Helmet } from 'react-helmet';

//stylesheets
import './BrandsPage.css';

//components
import MasterHeader from '../../components/header/MasterHeader';
import MasterFooter from '../../components/footer/MasterFooter';
import BrandsThumb from './BrandsThumb';

//assets


export default class BrandsPage extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { data } = this.props;

    console.log(data);
    return(
      <div className="main-brands-page">
        <MasterHeader />

        <div className="main-brands-container">


          <div className="main-brands-content">
            <Routes>
              <Route path="/" element={<BrandsLink brands={data} />} />
              <Route path=":brandSlug" element={<BrandsView brandCont={data} />} />
            </Routes>
          </div>
        </div>

        <MasterFooter />
      </div>
    )
  }
}

function BrandsLink(props) {
  console.log(props);
  return (
    <div className="main-brandslink-container">
      <div className="main-brandslink-info">
        <h1 className="main-brandslink-head">BRANDS WE'VE WORKED WITH</h1>
        <p className="main-brandslink-par">From our makeshift studio, we create stories aimed to share, shape, and showcase empowering and educational content in forms of music, art, and studio-shot videos.</p>
      </div>

      <div className="main-brandslink-div">
        <div className="main-brandslink-row">
          {props.brands.filter((brand) => (brand.sys.contentType.sys.id === 'brandPortfolio')).sort((a,b) => a.fields.brandName > b.fields.brandName ? 1 : -1).map((brand) => <BrandsThumb branding={brand.fields} key={brand.sys.id} />)}
        </div>
      </div>

      <div className="main-brandslink-ctapar-div">
        <h3 className="main-brandslink-ctapar">Like what you see?</h3>
        <div className="main-brandslink-cta">
          <Link to="collaborate">COLLABORATE WITH SIS</Link>
          <div className="main-brandslink-cta-otln"></div>
        </div>
      </div>
    </div>
  )
}

function BrandsView(props) {
  const { brandSlug } = useParams();

  const brandCont = props.brandCont;

  return (
    <div className="main-brandsview-container">
      <div className="brandsview-content-div">
        {brandCont.filter((brand) => (brand.fields.linkUrl === brandSlug)).map((brand) => (
          <BrandCont brandCont={brand.fields} key={brand.sys.id} />
        ))}
        <div className="brandsview-content-cta">
          <Link to="/collaborate">LET'S COLLABORATE!</Link>
        </div>
      </div>
    </div>
  )
}

function BrandCont(props) {
  const { brandContent } = props.brandCont;

  const { url } = brandContent.fields.file;

  return (
    <div className="brandsview-content-graphic">
      <img src={url} alt="Brand Portfolio" />
    </div>
  )
}
