import {
  Link
} from "react-router-dom";
import Moment from 'react-moment';

//stylesheets
import './ShowcaseDiv.css';

export default function ShowcaseDiv (props) {

  const { storyTitle, storyAuthor, publishDate, storyDescription, storyLink, storyThumbnail, ctaLink } = props.showcaseInfo;

  const { url } = storyThumbnail.fields.file;

  return (
    <div className="showcaselink-div">

      <div className="showcaselink-graphic">
        <img src={url} alt="Story Thumbnail" />
      </div>

      <div className="showcaselink-storyinfo">
        <div className="showcaselink-storyhead-div">
          <h3 className="showcaselink-storyhead">{storyTitle}</h3>
        </div>
        <div className="showcaselink-storypar-div">
          <p className="showcaselink-storypar">{storyDescription}</p>
        </div>
      </div>

      <div className="showcaselink-cta-btn">
        <ShowcaseIfLink storyLink={storyLink} ctaLink={ctaLink}/>
        <div className="showcaselink-cta-btn-otln"></div>
      </div>
    </div>
  )
}

function ShowcaseIfLink(props) {

  const { storyLink, ctaLink } = props;

  if (ctaLink === 'Watch Now') {
    return (
      // eslint-disable-next-line
      <a href={`${storyLink}`} target="_blank">{ctaLink}</a>
    );
  } else if (ctaLink === 'Read Now') {
    return (
      // eslint-disable-next-line
      <a href={`${storyLink}`} target="_blank">{ctaLink}</a>
    );
  } return (
    <Link to={`${storyLink}`}>{ctaLink}</Link>
  )
}
