import React, { Component } from 'react';
import {
  Link,
} from "react-router-dom";


//stylesheets
import './LandingPage.css';

//components
import LandingHeader from '../../components/header/LandingHeader';
import LandingFooter from '../../components/footer/LandingFooter';
import LandingSubsModal from './LandingSubsModal';
import LandingFeaturedDiv from './LandingFeaturedDiv';

//assets
import LdgHeroBackground from '../../assets/backgrounds/landing-gradient.jpg';
import LdgHeroDivider from '../../assets/backgrounds/landing-divider.png';
import LdgShowGrphc from '../../assets/images/ldg-show-img.png';
import LdgSharePhoto from '../../assets/images/ldg-share.png';
import LdgShapePhoto from '../../assets/images/ldg-shape.png';
import LdgShowcasePhoto from '../../assets/images/ldg-showcase.png';
import ContIcon from '../../assets/svg/content-icon.svg';
import CampaignIcon from '../../assets/svg/campaign-icon.svg';
import ChannIcon from '../../assets/svg/channel-icon.svg';
import CTABanner from '../../assets/images/cta-banner.png';

export default class LandingPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false
    }
  }

  showSubModal = (e) => {
    this.setState({
      showModal: !this.state.showModal
    })
  }

  render() {
    return (
      <div className="main-landing-page">
        <LandingHeader />
        <LandingSubsModal showModal={this.state.showModal} hideModal={this.showSubModal}/>

        <div className="ldg-hero-container" style={{backgroundImage: `url(${LdgHeroBackground})`}}>
          <div className="ldg-hero-div-top">
            <h1 className="ldg-hero-head">YOUR STORY</h1>
          </div>
          <div className="ldg-hero-div-mid">
            <div className="ldg-hero-subdiv">
              <h1 className="ldg-hero-head-mid">IS WORTH</h1>
            </div>
            <div className="ldg-hero-subdiv">
              <p className="ldg-hero-par"><b>Stories In Studios</b> is a multimedia platform by Sowenscale, aiming to share, shape, and showcase captivating, empowering, and educational content in forms of music, art, and videos.</p>
            </div>
          </div>
          <div className="ldg-hero-div-bot">
            <h1 className="ldg-hero-head">TELLING.</h1>
          </div>

          <div className="ldg-hero-cta-btn">
            <button onClick={e => {this.showSubModal()}}>SUBSCRIBE</button>
            <div className="ldg-hero-cta-btn-otln">
            </div>
          </div>

          <div className="ldg-hero-divider">
            <img src={LdgHeroDivider} alt="Divider" />
          </div>
        </div>



        <div className="ldg-show-container">
          <div className="ldg-show-graphic">
            <img src={LdgShowGrphc} alt="Landing Page Showcase Graphic" />
          </div>
        </div>



        <div className="ldg-sss-container">
          <div className="ldg-sss-info">
            <h2 className="ldg-sss-head">Untold Stories. Undeard-of Talents.</h2>
            <p className="ldg-sss-par">If not these stories, what else do we tell?</p>
          </div>

          <div className="ldg-sss-div">
            <div className="ldg-sss-subdiv">
              <div className="ldg-sss-subdiv-graphic">
                <img src={LdgSharePhoto} alt="Share Graphic" />
              </div>

              <div className="ldg-sss-subdiv-info">
                <h3 className="ldg-sss-subdiv-head">SHARE.</h3>
                <p className="ldg-sss-subdiv-par">Whether focusing on lifestyle or relevance, we share the stories of organizations in the food and beverage and essential services sectors.</p>

                <div className="ldg-sss-subdiv-cta">
                  <Link className="ldg-sss-subdiv-cta-btn" to="/share">READ MORE</Link>
                  <div className="ldg-sss-subdiv-cta-otln"></div>
                </div>
              </div>
            </div>



            <div className="ldg-sss-subdiv">
              <div className="ldg-sss-subdiv-graphic">
                <img src={LdgShapePhoto} alt="Shape Graphic" />
              </div>

              <div className="ldg-sss-subdiv-info">
                <h3 className="ldg-sss-subdiv-head">SHAPE.</h3>
                <p className="ldg-sss-subdiv-par">Through telling the stories of advocacy groups and institutions, we help shape mindset with their impact.</p>

                <div className="ldg-sss-subdiv-cta">
                  <Link className="ldg-sss-subdiv-cta-btn" to="/shape">LEARN MORE</Link>
                  <div className="ldg-sss-subdiv-cta-otln"></div>
                </div>
              </div>
            </div>



            <div className="ldg-sss-subdiv">
              <div className="ldg-sss-subdiv-graphic">
                <img src={LdgShowcasePhoto} alt="Showcase Graphic" />
              </div>

              <div className="ldg-sss-subdiv-info">
                <h3 className="ldg-sss-subdiv-head">SHOWCASE.</h3>
                <p className="ldg-sss-subdiv-par">We make others see the undeniable talent and art created and honed by Filipino musicians and artists by showcasing their stories.</p>

                <div className="ldg-sss-subdiv-cta">
                  <Link className="ldg-sss-subdiv-cta-btn" to="/showcase">PLAY MORE</Link>
                  <div className="ldg-sss-subdiv-cta-otln"></div>
                </div>
              </div>
            </div>

          </div>
        </div>



        <div className="ldg-ult-container">
          <div className="ldg-ult-info">
            <h1 className="ldg-ult-head">Our ultimate goal is to let your audience know your story, and <p className="ldg-ult-head-wt">pass it on.</p></h1>
          </div>


          <div className="ldg-ult-divider"></div>


          <div className="ldg-ult-ccc-div">

            <div className="ldg-ult-ccc-subdiv">
              <div className="ldg-ult-ccc-divider"></div>

              <div className="ldg-ult-ccc-graphic">
                <img src={ContIcon} alt="Content Icon" />
              </div>

              <div className="ldg-ult-ccc-info">
                <h3 className="ldg-ult-ccc-head">CONTENT</h3>
                <p className="ldg-ult-ccc-par">Through our Content Writing Services, we can create and share captivating and engaging written content and stories for your audience.</p>
              </div>
            </div>


            <div className="ldg-ult-ccc-subdiv">
              <div className="ldg-ult-ccc-divider"></div>

              <div className="ldg-ult-ccc-graphic">
                <img src={CampaignIcon} alt="Campaign Icon" />
              </div>

              <div className="ldg-ult-ccc-info">
                <h3 className="ldg-ult-ccc-head">CAMPAIGN</h3>
                <p className="ldg-ult-ccc-par">SIS can help shape your impact through our Campaign Development and Implementation Services.</p>
              </div>
            </div>


            <div className="ldg-ult-ccc-subdiv">
              <div className="ldg-ult-ccc-divider"></div>

              <div className="ldg-ult-ccc-graphic">
                <img src={ChannIcon} alt="Channel Icon" />
              </div>

              <div className="ldg-ult-ccc-info">
                <h3 className="ldg-ult-ccc-head">CHANNEL</h3>
                <p className="ldg-ult-ccc-par">Sometimes, stories are better shared in photos and videos and we can do that for you through our Content Creation Services.</p>
              </div>
            </div>

          </div>

          <div className="ldg-ult-ccc-cta">
            <Link to="/services">LEARN MORE</Link>
            <div className="ldg-ult-ccc-cta-otln"></div>
          </div>
        </div>



        <div className="ldg-brands-container">
          <div className="ldg-brands-info">
            <h2 className="ldg-brands-head">Were We Successful?</h2>
            <p className="ldg-brands-par">Read. Watch. Listen</p>
          </div>

          <div className="ldg-brands-links">
            {this.props.data.filter((featLink) => (featLink.sys.contentType.sys.id === 'storyPost' && featLink.fields.featuredStory === 'Featured')).sort((a,b) => a.fields.publishDate < b.fields.publishDate ? 1 : -1).map((featLink) => <LandingFeaturedDiv featInfo={featLink.fields} key={featLink.sys.id} />)}
          </div>

        </div>



        <div className="ldg-cta-banner">
          <div className="ldg-cta-banner-info">
            <div className="ldg-cta-banner-head-div">
              <h1 className="ldg-cta-banner-head">Up for a collab? <br/>Hit us up!</h1>
            </div>
            <div className="ldg-cta-banner-btn">
              <Link to="/collaborate">COLLABORATE WITH SIS</Link>
              <div className="ldg-cta-banner-otln">
              </div>
            </div>
          </div>
          <div className="ldg-cta-banner-graphic">
            <img src={CTABanner} alt="CTA Graphic" />
          </div>
        </div>

        <LandingFooter />
      </div>
    )
  }
}
