import React, { Component } from 'react';
import {
  Link,
} from "react-router-dom";

//stylesheets
import './LandingFooter.css';
//components

//assets
import FtrLogoBlk from '../../assets/logo/sis-logo-onblk.png';
import FbIco from '../../assets/svg/fb-ico.svg';
import IgIco from '../../assets/svg/ig-ico.svg';
import LiIco from '../../assets/svg/li-ico.svg';

export default class LandingFooter extends Component {
  render() {
    return (
      <footer className="ldg-footer">
        <div className="ldg-footer-row">
          <div className="ldg-footer-col">
            <p className="ldg-footer-lbl">STAY CONNECTED</p>
            <div className="ldg-footer-icodiv">
              <a href="">
                <img src={FbIco} alt="Facebook Icon" />
              </a>
              <a href="">
                <img src={IgIco} alt="Instagram Icon" />
              </a>
              <a href="">
                <img src={LiIco} alt="LinkedIn Icon" />
              </a>
            </div>
          </div>

          <div className="ldg-footer-col">
            <img className="ldg-footer-logo" src={FtrLogoBlk} alt="SIS Logo"/>
          </div>

          <div className="ldg-footer-col">
            <p className="ldg-footer-lbl">GOT QUESTIONS?</p>
            <p className="ldg-footer-cnt">+63 905 555 5555</p>
            <p className="ldg-footer-cnt">admin@sowenscale.com</p>
          </div>
        </div>
        <div className="ldg-footer-row">

        </div>
        <div className="ldg-footer-row">
          <p className="ldg-footer-cpyrt">&copy; Stories in Studios. All rights reserved.</p>
        </div>
      </footer>
    )
  }
}
