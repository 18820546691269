import React, { Component } from 'react';
import {
  Link,
} from "react-router-dom";

//stylesheets
import './LandingHeader.css';
//components
import LandingHeaderMobile from './LandingHeaderMobile';

//assets
import LandingLogo from '../../assets/logo/sis-logo.png';

export default class LandingHeader extends Component {
  constructor(){
    super();
    this.state = {
      ldgHdrScrl: false
    }
  }

  UNSAFE_componentWillMount() {

    const ldgChangeNavBackground = () => {
      if (window.scrollY >= 80) {
        this.setState({ldgHdrScrl: true});
      } else if (window.scrollY < 80 && window.scrollY === 0) {
        this.setState({ldgHdrScrl: false});
      }
    }

    window.addEventListener('scroll', ldgChangeNavBackground);



  }



  render() {
    const { ldgHdrScrl } = this.state;

    return (
      <header className={ldgHdrScrl ? 'ldg-header-scrl' : 'ldg-header'}>
        <Link className="sis-logo-ldg" to="/"><img src={LandingLogo} alt="SIS Logo" /></Link>

        <nav className="ldg-nav">
          <ul className="ldg-navlinks">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/share">SHARE</Link>
            </li>
            <li>
              <Link to="/shape">SHAPE</Link>
            </li>
            <li>
              <Link to="/showcase">SHOWCASE</Link>
            </li>
            <li>
              <Link to="/services">SERVICES</Link>
            </li>
            <li>
              <Link to="/inquire">INQUIRE</Link>
            </li>
          </ul>
        </nav>

        <div className="ldg-collab-btn">
          <Link to="/collaborate">COLLABORATE</Link>
          <div className="ldg-collab-btn-otln">
          </div>
        </div>

        <LandingHeaderMobile />

      </header>
    )
  }
}
