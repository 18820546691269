import React, { Component } from 'react';
import {
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { Helmet } from 'react-helmet';

//stylesheets
import './ShowcasePage.css';

//components
import MasterHeader from '../../components/header/MasterHeader';
import MasterFooter from '../../components/footer/MasterFooter';
import ShowcaseLink from './ShowcaseLink';
import ShowcaseCont from './ShowcaseCont';

//assets

export default class ShowcasePage extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { data } = this.props;

    return (
      <div className="main-showcase-page">
        <MasterHeader />

        <div className="main-showcase-container">

          <div className="main-showcase-content">
            <Routes>
              <Route path="/" element={<ShowcaseLink showcaseLinks={data} />} />
              <Route path=":storySlug" element={<ShowcaseView showcaseCont={data} />} />
            </Routes>
          </div>
        </div>

        <MasterFooter />
      </div>
    )
  }
}

function ShowcaseView(props) {
  const { storySlug } = useParams();
  const showcaseCont = props.showcaseCont;

  return (
    <div className="showcaseview-div">
      {showcaseCont.filter((showcaseData) => (showcaseData.fields.storyLink === storySlug)).map((showcaseContent) => ( <ShowcaseCont showcaseCont={showcaseContent.fields} key={showcaseContent.sys.id} />
      ))}
    </div>
  )
}
