import React, { Component } from 'react';
import {
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { Helmet } from 'react-helmet';

//stylesheets


//components
import ShowcaseDiv from './ShowcaseDiv';


//assets


export default class ShowcaseLink extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="main-showcaselink-container">
        <div className="main-showcaselink-info">
          <div className="main-showcaselink-head-div">
            <h1 className="main-showcaselink-head">WE WANT TO</h1>
            <h1 className="main-showcaselink-head-hl">SHOWCASE</h1>
            <h1 className="main-showcaselink-head-end">YOUR TALENT</h1>
          </div>
          <p className="main-showcaselink-par">From our makeshift studio, we create stories aimed to share, shape, and showcase empowering and educational content in forms of music, art, and studio-shot videos.</p>
        </div>

        <div className="main-showcaselink-div">
          {this.props.showcaseLinks.filter((showcaseLink) => (showcaseLink.sys.contentType.sys.id === 'storyPost' && showcaseLink.fields.storyType === 'Showcase')).sort((a,b) => a.fields.publishDate < b.fields.publishDate ? 1 : -1).map((showcaseLink) => <ShowcaseDiv showcaseInfo={showcaseLink.fields} key={showcaseLink.sys.id} />)}
        </div>

      </div>
    )
  }
}
