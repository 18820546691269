import React, { Component, useState } from 'react';
import MCSubscribe from 'react-mailchimp-subscribe';

//stylesheets
import './LandingSubsModal.css'

//assets
//import CloseBtn from '../../assets/svg/closebtn-icn.svg'

export default class LandingSubsModal extends Component {

  hideSubModal = (e) => {
    this.props.hideModal && this.props.hideModal(e)
  }



  render() {
    const formURL = `https://sowenscale.us1.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MCU}&id=${process.env.REACT_APP_MCID}`;

    if (!this.props.showModal) {
      return null;
    } return (
      <div className="main-cta-subsmodal-container">
        <div className="subsmodal-div">
          <div className="subsmodal-ctrl">
            <button className="subsmodal-ctrl-cls" onClick={e => {this.hideSubModal()}}><img src="" alt="Close Button" /></button>
          </div>

          <div className="subsmodal-header">
            <h3 className="subsmodal-head">Subscribe to Stories in Studios</h3>
          </div>

          <div className="subsmodal-content">
            <MCSubscribe url={formURL}
            render={({subscribe, status, message}) => (<MCForm
              status={status}
              message={message}
              onValidated={formData => subscribe(formData)}
            />)}/>
          </div>
        </div>
      </div>
    )
  }
}

function MCForm({status, message, onValidated}) {
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    fullName &&
    email.indexOf("@") > -1 &&
    onValidated({
        EMAIL: email,
        MERGE2: fullName,
      });
    }

  return (
    <div className="sis-subscribe-container">
      <form className="sis-subscribe-form" onSubmit={(e) => handleSubmit(e)}>
        <div className="sis-subscribe-status">
          {status === "sending" && (
            <div className="ss-sending">
              sending...
            </div>
          )}
          {status === "error" && (
            <div className="ss-error"
            dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
          {status === "success" && (
            <div className="ss-success"
            dangerouslySetInnerHTML={{ __html: message }}
            />
          )}
        </div>

        <div className="sis-subscribe-field">
          <MCInput
            onChangeHandler={setFullName}
            type="text"
            value={fullName}
            placeholder="Full Name"
            isRequired
            />
        </div>

        <div className="sis-subscribe-field">
          <MCInput
            onChangeHandler={setEmail}
            type="text"
            value={email}
            placeholder="Email"
            isRequired
            />
        </div>

        <div className="sis-subscribe-field">
          <MCInput
            type="submit"
            formValues={[email, fullName]}
            />
        </div>
      </form>
    </div>
  )
}

function MCInput(props) {
  const validateInput = values => {
    if (values.some(f => f === "") || values[0].indexOf("@") === -1) {
      return true
    } else {
      return false
    }
  }


  if (props.type === "submit") {
    return (
      <input
        className="sis-subscribe-submit"
        type='submit'
        disabled={validateInput(props.formValues)}
      />
    )
  } else if (props.type === "textarea") {
    return (
      <input
        type="textarea"
      />
    );
  } else {
    return (
      <input
        onChange={(e) => props.onChangeHandler(e.target.value)}
        type={props.type}
        placeholder={props.placeholder}
        value={props.value}
        required={props.isRequired}
        className="sis-subscribe-input"
        name={props.name}
      />
    );
  }
}
