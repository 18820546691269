import React, { Component } from 'react';
import {
  Link,
} from "react-router-dom";

//stylesheets
import './MasterFooter.css';
//components

//assets
import FtrLogo from '../../assets/logo/sis-logo.png';
import FbIcoBlk from '../../assets/svg/fb-ico-blk.svg';
import IgIcoBlk from '../../assets/svg/ig-ico-blk.svg';
import LiIcoBlk from '../../assets/svg/li-ico-blk.svg';

export default class MasterFooter extends Component {
  render() {
    return (
      <footer className="footer">
        <div className="footer-row">
          <div className="footer-col">
            <p className="footer-lbl">STAY CONNECTED</p>
            <div className="footer-icodiv">
              <a href="">
                <img src={FbIcoBlk} alt="Facebook Icon" />
              </a>
              <a href="">
                <img src={IgIcoBlk} alt="Instagram Icon" />
              </a>
              <a href="">
                <img src={LiIcoBlk} alt="LinkedIn Icon" />
              </a>
            </div>
          </div>

          <div className="footer-col">
            <img className="footer-logo" src={FtrLogo} alt="SIS Logo"/>
          </div>

          <div className="footer-col">
            <p className="footer-lbl">GOT QUESTIONS?</p>
            <p className="footer-cnt">+63 905 555 5555</p>
            <p className="footer-cnt">admin@sowenscale.com</p>
          </div>
        </div>
        <div className="footer-row">

        </div>
        <div className="footer-row">
          <p className="footer-cpyrt">&copy; Stories in Studios. All rights reserved.</p>
        </div>
      </footer>
    )
  }
}
