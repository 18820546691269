import React, { Component } from 'react';
import {
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { Helmet } from 'react-helmet';

//stylesheets


//components
import ShapeDiv from './ShapeDiv';

//assets


export default class ShapeLink extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="main-shapelink-container">
        <div className="main-shapelink-info">
          <div className="main-shapelink-head-div">
            <h1 className="main-shapelink-head">WE HELP</h1>
            <h1 className="main-shapelink-head-hl">SHAPE</h1>
            <h1 className="main-shapelink-head-end">YOUR IMPACT</h1>
          </div>
          <p className="main-shapelink-par">Through telling the stories of advocacy groups and institutions, we help shape mindset with their impact.</p>
        </div>

        <div className="main-shapelink-div">
          {this.props.shapeLinks.filter((shapeLink) => (shapeLink.sys.contentType.sys.id === 'storyPost' && shapeLink.fields.storyType === 'Shape')).sort((a,b) => a.fields.publishDate < b.fields.publishDate ? 1 : -1).map((shapeLink) => <ShapeDiv shapeInfo={shapeLink.fields} key={shapeLink.sys.id} />)}
        </div>

      </div>
    )
  }
}
