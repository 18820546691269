import React, { Component } from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";
import { Helmet } from 'react-helmet';
import * as contentful from 'contentful';

//stylesheets
import './assets/fonts/sis-fonts.css';
import './App.css';

//components

//assets

//views
import LandingPage from './views/landing/LandingPage';
import SharePage from './views/share/SharePage';
import ShapePage from './views/shape/ShapePage';
import ShowcasePage from './views/showcase/ShowcasePage';
import ServicesPage from './views/services/ServicesPage';
import BrandsPage from './views/brands/BrandsPage';
import InquirePage from './views/inquire/InquirePage';
import CollaboratePage from './views/collaborate/CollaboratePage';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dataEntries: [],
    }
  }

  UNSAFE_componentWillMount() {
    const {
      REACT_APP_SPACE_ID,
      REACT_APP_SPACE_ACC_TOK
    } = process.env;

    const client = contentful.createClient({
      space: REACT_APP_SPACE_ID,
      accessToken: REACT_APP_SPACE_ACC_TOK
    })

    client.getEntries()
    .then((res) => {
      this.setState({
        dataEntries: res.items
      })
    })
    .catch(console.error)
  }

  render() {

    return (
      <div className="App">
      <Routes>
        <Route path="/" element={<LandingPage data={this.state.dataEntries} />} />
        <Route path="/share/*" element={<SharePage data={this.state.dataEntries} />} />
        <Route path="/shape/*" element={<ShapePage data={this.state.dataEntries} />} />
        <Route path="/showcase/*" element={<ShowcasePage data={this.state.dataEntries} />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/brands/*" element={<BrandsPage data={this.state.dataEntries} />} />
        <Route path="/inquire" element={<InquirePage />} />
        <Route path="/collaborate" element={<CollaboratePage />} />
      </Routes>
      </div>
    );
  }
}
