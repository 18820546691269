import {
  Link
} from "react-router-dom";

//stylesheets
import './BrandsThumb.css';

export default function BrandThumb (props) {

  const { brandName, servicesRendered, brandThumbnail, linkUrl } = props.branding;

  const { url } = brandThumbnail.fields.file;

  return (

    <div className="brandslink-thumb">
      <Link to={`${linkUrl}`}>
        <img src={url} alt="Brand Thumbnail"/>
      </Link>

      <div className="brandslink-desc-div">
          <p className="brandslink-name">{brandName}</p>
      </div>
    </div>
  )

}
