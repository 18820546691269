import React, { Component } from 'react';
import {
  Link,
} from "react-router-dom";

//stylesheets
import './ServicesPage.css';

//components
import MasterHeader from '../../components/header/MasterHeader';
import MasterFooter from '../../components/footer/MasterFooter';

//assets
import SNum1 from '../../assets/svg/snum1.svg';
import SNum2 from '../../assets/svg/snum2.svg';
import SNum3 from '../../assets/svg/snum3.svg';
import SNum4 from '../../assets/svg/snum4.svg';
import SNum5 from '../../assets/svg/snum5.svg';
import SNum6 from '../../assets/svg/snum6.svg';
import SNum7 from '../../assets/svg/snum7.svg';
import SNum8 from '../../assets/svg/snum8.svg';


export default class ServicesPage extends Component {
  constructor() {
    super();

    this.state = {

    }
  }

  render() {
    return (
      <div className="main-services-page">
        <MasterHeader />

        <div className="main-services-container">
          <div className="main-services-info">
            <h1 className="main-services-head">Our ultimate goal is to let your audience know your story, and pass it on.</h1>
          </div>

          <div className="main-services-div">
            <div className="main-services-subdiv">
              <div className="main-services-subhead-div">
                <h1 className="main-services-subhead">SHARE.</h1>
              </div>

              <div className="main-services-row">
                <div className="services-info">
                  <div className="services-dln">
                  </div>
                  <div className="services-par-div">
                    <p className="services-par">Through our Content Writing Services, we can create and share captivating and engaging written content and stories for your audience.</p>
                  </div>
                </div>

                <div className="services-div">
                  <div className="services-row">
                    <div className="services-num">
                      <img src={SNum1} alt="Number" />
                    </div>
                    <div className="services-title">
                      <p className="service-title">Technical Content</p>
                    </div>
                  </div>

                  <div className="services-row-blnk">
                    <div className="services-num-blnk">
                    </div>
                    <div className="services-title-blnk">
                    </div>
                  </div>

                  <div className="services-row">
                    <div className="services-num">
                      <img src={SNum2} alt="Number" />
                    </div>
                    <div className="services-title">
                      <p className="service-title">Content for SEO</p>
                    </div>
                  </div>

                  <div className="services-row-blnk">
                    <div className="services-num-blnk">
                    </div>
                    <div className="services-title-blnk">
                    </div>
                  </div>

                  <div className="services-row">
                    <div className="services-num">
                      <img src={SNum3} alt="Number" />
                    </div>
                    <div className="services-title">
                      <p className="service-title">Instructional Design and Storyboarding</p>
                    </div>
                  </div>

                  <div className="services-row-blnk">
                    <div className="services-num-blnk">
                    </div>
                    <div className="services-title-blnk">
                    </div>
                  </div>

                  <div className="services-row">
                    <div className="services-num">
                      <img src={SNum4} alt="Number" />
                    </div>
                    <div className="services-title">
                      <p className="service-title">Press Release</p>
                    </div>
                  </div>

                  <div className="services-row-blnk">
                    <div className="services-num-blnk">
                    </div>
                    <div className="services-title-blnk">
                    </div>
                  </div>

                  <div className="services-row">
                    <div className="services-num">
                      <img src={SNum5} alt="Number" />
                    </div>
                    <div className="services-title">
                      <p className="service-title">Ghost Writing</p>
                    </div>
                  </div>

                  <div className="services-row-blnk">
                    <div className="services-num-blnk">
                    </div>
                    <div className="services-title-blnk">
                    </div>
                  </div>

                  <div className="services-row">
                    <div className="services-num">
                      <img src={SNum6} alt="Number" />
                    </div>
                    <div className="services-title">
                      <p className="service-title">Guest Posting</p>
                    </div>
                  </div>

                  <div className="services-row-blnk">
                    <div className="services-num-blnk">
                    </div>
                    <div className="services-title-blnk">
                    </div>
                  </div>

                  <div className="services-row">
                    <div className="services-num">
                      <img src={SNum7} alt="Number" />
                    </div>
                    <div className="services-title">
                      <p className="service-title">Social Media Copies</p>
                    </div>
                  </div>
                </div>
              </div>





              <div className="main-services-subdiv">
                <div className="main-services-subhead-div">
                  <h1 className="main-services-subhead">SHAPE.</h1>
                </div>

                <div className="main-services-row">
                  <div className="services-info">
                    <div className="services-dln">
                    </div>
                    <div className="services-par-div">
                      <p className="services-par">SIS can help shape your impact through our Campaign Development and Implementation Services.</p>
                    </div>
                  </div>

                  <div className="services-div">
                    <div className="services-row">
                      <div className="services-num">
                        <img src={SNum1} alt="Number" />
                      </div>
                      <div className="services-title">
                        <p className="service-title">Product and/or Service Advertisements</p>
                      </div>
                    </div>

                    <div className="services-row-blnk">
                      <div className="services-num-blnk">
                      </div>
                      <div className="services-title-blnk">
                      </div>
                    </div>

                    <div className="services-row">
                      <div className="services-num">
                        <img src={SNum2} alt="Number" />
                      </div>
                      <div className="services-title">
                        <p className="service-title">Media Buying</p>
                      </div>
                    </div>

                    <div className="services-row-blnk">
                      <div className="services-num-blnk">
                      </div>
                      <div className="services-title-blnk">
                      </div>
                    </div>

                    <div className="services-row">
                      <div className="services-num">
                        <img src={SNum3} alt="Number" />
                      </div>
                      <div className="services-title">
                        <p className="service-title">Influencer Relations</p>
                      </div>
                    </div>

                    <div className="services-row-blnk">
                      <div className="services-num-blnk">
                      </div>
                      <div className="services-title-blnk">
                      </div>
                    </div>

                    <div className="services-row">
                      <div className="services-num">
                        <img src={SNum4} alt="Number" />
                      </div>
                      <div className="services-title">
                        <p className="service-title">Print and Digital Designs and Assets</p>
                      </div>
                    </div>

                  </div>
                </div>
              </div>





              <div className="main-services-subdiv">
                <div className="main-services-subhead-div">
                  <h1 className="main-services-subhead">SHOWCASE.</h1>
                </div>

                <div className="main-services-row">
                  <div className="services-info">
                    <div className="services-dln">
                    </div>
                    <div className="services-par-div">
                      <p className="services-par">Sometimes, stories are better shared in photos and videos and we can do that for you through our Content Creation Services.</p>
                    </div>
                  </div>

                  <div className="services-div">
                    <div className="services-row">
                      <div className="services-num">
                        <img src={SNum1} alt="Number" />
                      </div>
                      <div className="services-title">
                        <p className="service-title">Commercial Jingles</p>
                      </div>
                    </div>

                    <div className="services-row-blnk">
                      <div className="services-num-blnk">
                      </div>
                      <div className="services-title-blnk">
                      </div>
                    </div>

                    <div className="services-row">
                      <div className="services-num">
                        <img src={SNum2} alt="Number" />
                      </div>
                      <div className="services-title">
                        <p className="service-title">Product and Service Shoot</p>
                      </div>
                    </div>

                    <div className="services-row-blnk">
                      <div className="services-num-blnk">
                      </div>
                      <div className="services-title-blnk">
                      </div>
                    </div>

                    <div className="services-row">
                      <div className="services-num">
                        <img src={SNum3} alt="Number" />
                      </div>
                      <div className="services-title">
                        <p className="service-title">Short Clips</p>
                      </div>
                    </div>

                    <div className="services-row-blnk">
                      <div className="services-num-blnk">
                      </div>
                      <div className="services-title-blnk">
                      </div>
                    </div>

                    <div className="services-row">
                      <div className="services-num">
                        <img src={SNum4} alt="Number" />
                      </div>
                      <div className="services-title">
                        <p className="service-title">Vlog</p>
                      </div>
                    </div>

                    <div className="services-row-blnk">
                      <div className="services-num-blnk">
                      </div>
                      <div className="services-title-blnk">
                      </div>
                    </div>

                    <div className="services-row">
                      <div className="services-num">
                        <img src={SNum5} alt="Number" />
                      </div>
                      <div className="services-title">
                        <p className="service-title">Music Video Production</p>
                      </div>
                    </div>

                    <div className="services-row-blnk">
                      <div className="services-num-blnk">
                      </div>
                      <div className="services-title-blnk">
                      </div>
                    </div>

                    <div className="services-row">
                      <div className="services-num">
                        <img src={SNum6} alt="Number" />
                      </div>
                      <div className="services-title">
                        <p className="service-title">Virtual or Live Event Coverage</p>
                      </div>
                    </div>

                    <div className="services-row-blnk">
                      <div className="services-num-blnk">
                      </div>
                      <div className="services-title-blnk">
                      </div>
                    </div>

                    <div className="services-row">
                      <div className="services-num">
                        <img src={SNum7} alt="Number" />
                      </div>
                      <div className="services-title">
                        <p className="service-title">Video Animation</p>
                      </div>
                    </div>

                    <div className="services-row-blnk">
                      <div className="services-num-blnk">
                      </div>
                      <div className="services-title-blnk">
                      </div>
                    </div>

                    <div className="services-row">
                      <div className="services-num">
                        <img src={SNum8} alt="Number" />
                      </div>
                      <div className="services-title">
                        <p className="service-title">Podcast Production</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


            </div>

            <div className="main-services-cta">
              <Link to="/collaborate">COLLABORATE WITH SIS</Link>
              <div className="main-services-cta-otln"></div>
            </div>
          </div>
        </div>

        <MasterFooter />
      </div>
    )
  }
}
