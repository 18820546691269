import {
  Link,
} from "react-router-dom";

export default function LandingHeaderMobile() {
  function navDrop() {
    document.getElementById('ldg-nav-mob-links').classList.toggle('show-nav-mob')
  }

  window.onclick = function(event) {
    if (!event.target.matches('.ldg-nav-mob-btn')) {
      var dropdowns = document.getElementsByClassName("ldg-nav-mob-links");
      var i;
      for (i = 0; i < dropdowns.length; i++) {
        var openDropdown = dropdowns[i];
        if (openDropdown.classList.contains('show-nav-mob')) {
          openDropdown.classList.remove('show-nav-mob');
        }
      }
    }
  }

  return (
    <div className="ldg-nav-mob">
      <button onClick={navDrop} type="button" className="ldg-nav-mob-btn"></button>
      <div className="ldg-nav-mob-links" id="ldg-nav-mob-links">
        <Link className="ldg-navm-link" to="/">HOME</Link>
        <Link className="ldg-navm-link" to="/share">SHARE</Link>
        <Link className="ldg-navm-link" to="/shape">SHAPE</Link>
        <Link className="ldg-navm-link" to="/showcase">SHOWCASE</Link>
        <Link className="ldg-navm-link" to="/services">SERVICES</Link>
        <Link className="ldg-navm-link" to="/inquire">INQUIRE</Link>
        <Link className="ldg-navm-link-collab" to="/collaborate">COLLABORATE</Link>
      </div>
    </div>
  )
}
