import React, { Component } from 'react';
import {
  Link,
} from "react-router-dom";

//stylesheets
import './InquirePage.css';

//components
import MasterHeader from '../../components/header/MasterHeader';
import MasterFooter from '../../components/footer/MasterFooter';

//assets


export default class InquirePage extends Component {
  constructor() {
    super();

    this.state = {
      fullName: "",
      inquireEmail: "",
      inquireContact: "",
      inquireMessage: "",
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked && target.value : target.value;
    const name = target.name;

    console.log(value);
    this.setState({
      [name]: value
    })
  }

  handleOnSubmit = (e) => {
    e.preventDefault();

    const {
      fullName,
      inquireEmail,
      inquireContact,
      inquireMessage,
    } = this.state;

    const inquireFormContent = {
      fullName,
      inquireEmail,
      inquireContact,
      inquireMessage,
    }

    fetch('/collaborate', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(inquireFormContent)
    }).then((res) => {
      console.log('Form Submitted');
    }).catch((err) => {
      console.error(err);
    })

  }

  render() {
    return (
      <div className="main-inquire-page">
        <MasterHeader />

        <div className="main-inquire-container">
          <div className="main-inquire-info">
            <p className="main-inquire-ctapar">ASK US ANYTHING!</p>
            <h1 className="main-inquire-head">Ask big names in your industry,<br />None of them made it alone.</h1>
          </div>

          <div className="main-inquire-form">
            <div className="inquire-form-div">
              <form className="inquire-form" method="post" onSubmit={this.handleOnSubmit}>
                <div className="inquire-formgroup">
                  <p className="inquire-formlabel">NAME*</p>
                  <input type="text"
                  id="fullName"
                  name="fullName" value={this.state.fullName} onChange={this.handleChange} className="inquire-input" />
                </div>

                <div className="inquire-formgroup">
                  <p className="inquire-formlabel">EMAIL ADDRESS*</p>
                  <input type="email"
                  id="inquireEmail"
                  name="inquireEmail" value={this.state.inquireEmail} onChange={this.handleChange} className="inquire-input" />
                </div>

                <div className="inquire-formgroup">
                  <p className="inquire-formlabel">CONTACT NUMBER*</p>
                  <input type="text"
                  id="inquireContact"
                  name="inquireContact" value={this.state.inquireContact} onChange={this.handleChange} className="inquire-input" />
                </div>

                <div className="inquire-formgroup">
                  <p className="inquire-formlabel">MESSAGE*</p>
                  <textarea type="text"
                  id="inquireMessage" name="inquireMessage" value={this.state.inquireMessage}onChange={this.handleChange} className="inquire-textarea" rows="6"/>
                </div>

                <div className="inquire-formsubmit">
                  <button className="inquire-submit" type="submit">SUBMIT</button>
                  <div className="inquire-submit-btn-otln"></div>
                </div>
              </form>
            </div>
          </div>

        </div>

        <MasterFooter />
      </div>
    )
  }
}
