import React, { Component } from 'react';
import {
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { Helmet } from 'react-helmet';

//stylesheets


//components
import ShareDiv from './ShareDiv';


//assets


export default class ShareLink extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="main-sharelink-container">
        <div className="main-sharelink-info">
          <div className="main-sharelink-head-div">
            <h1 className="main-sharelink-head">WE WANT TO</h1>
            <h1 className="main-sharelink-head-hl">SHARE</h1>
            <h1 className="main-sharelink-head-end">YOUR STORY</h1>
          </div>
          <p className="main-sharelink-par">Whether focusing on lifestyle or relevance, we share the stories of organizations in the food and beverage and essential services sectors.</p>
        </div>

        <div className="main-sharelink-div">
          {this.props.shareLinks.filter((shareLink) => (shareLink.sys.contentType.sys.id === 'storyPost' && shareLink.fields.storyType === 'Share')).sort((a,b) => a.fields.publishDate < b.fields.publishDate ? 1 : -1).map((shareLink) => <ShareDiv shareInfo={shareLink.fields} key={shareLink.sys.id} />)}
        </div>

      </div>
    )
  }
}
