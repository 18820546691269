import React, { Component } from 'react';
import {
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { Helmet } from 'react-helmet';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import Moment from 'react-moment';

//stylesheets
import './ShapeCont.css';

export default class ShapeCont extends Component{
  constructor(props) {
    super(props);
  }

  render() {

    const { storyTitle, storyAuthor, publishDate, storyThumbnail, storyContent, storyLink, metaDescription, storyAuthorLink, storyTags } = this.props.shapeCont;

    const { url } = storyThumbnail.fields.file;

    const RICHTEXT_OPTIONS = {
      renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => {
          return <p>{children}</p>
        },

        [BLOCKS.EMBEDDED_ASSET]: (node) => {
          const { url } = node.data.target.fields.file;
          return <img className="shapecont-cnt-image" src={url} alt="Story Graphic" />
        },

        [BLOCKS.HEADING_1]: (node, children) => {
          return <h1 className="shapecont-cnt-h1">{children}</h1>
        },

        [BLOCKS.HEADING_2]: (node, children) => {
          return <h2 className="shapecont-cnt-h2">{children}</h2>
        },

        [BLOCKS.HEADING_3]: (node, children) => {
          return <h3 className="sharecont-cnt-h3">{children}</h3>
        },

        [BLOCKS.HEADING_4]: (node, children) => {
          return <h4 className="shapecont-cnt-h4">{children}</h4>
        },

        [BLOCKS.HEADING_5]: (node, children) => {
          return <h5 className="shapecont-cnt-h5">{children}</h5>
        },

        [BLOCKS.HEADING_6]: (node, children) => {
          return <h6 className="shapecont-cnt-h6">{children}</h6>
        },

        [BLOCKS.HR]: (node, children) => {
          return <div className="shapecont-cnt-divider">{children}</div>
        },

        [INLINES.HYPERLINK]: (node, children) => {
          const { uri } = node.data;
          return <a className="shapecont-cnt-link" href={uri}>{children}</a>
        }
      },

      renderMark: {
        [MARKS.BOLD]: text => <b>{text}</b>
      }
    }

    return (
      <div className="shapecont-div">
        <Helmet>
          <title>Stories in Studios | {storyTitle}</title>
          <meta property="og:title" content={`${storyTitle} | Stories in Studios`} />
          <meta name="description" content={`${metaDescription}`} />
          <meta property="og:description" content={`${metaDescription}`} />
          <meta property="og:type" content="article" />
          <meta property="og:url" content={`http://storiesinstudios/stories/${storyLink}`} />
          <meta property="og:site_name" content="Stories in Studios" />
          <meta property="og:image" content={`https:${url}`} />
          <meta name="author" content={storyAuthor} />
          <meta name="keywords" content={storyTags} />
        </Helmet>



        <div className="shapecont-cnt-div">
          <div className="shapecont-cnt-info">
            <h2 className="shapecont-cnt-head">{storyTitle}</h2>

            <div className="shapecont-cnt-subinfo">
              <p className="shapecont-cnt-author">{storyAuthor} |</p>
              <p className="shapecont-cnt-date"><Moment format="MMMM D, YYYY">{publishDate}</Moment></p>
            </div>
          </div>

          <div className="shapecont-cnt-banner">
            <img src={url} alt="Story Banner" />
          </div>

          <div className="shapecont-cnt">
            {documentToReactComponents(storyContent, RICHTEXT_OPTIONS)}
          </div>

          <StoryAuthorLink authorData={storyAuthorLink} />



        </div>


      </div>
    )
  }
}

function StoryAuthorLink(props) {
  const { authorName, authorBio, authorPhoto } = props.authorData.fields;

  const { url } = authorPhoto.fields.file;

  return (
    <div className="cont-cnt-author">
      <div className="cont-cnt-authorphoto">
        <img src={url} alt="Author Portrait" />
      </div>

      <div className="cont-cnt-authorinfo">
        <h4 className="cont-cnt-authorname">{authorName}</h4>
        <p className="cont-cnt-authorbio">{authorBio}</p>
      </div>
    </div>
  )
}
