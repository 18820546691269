import React, { Component } from 'react';
import {
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { Helmet } from 'react-helmet';

//stylesheets
import './SharePage.css';

//components
import MasterHeader from '../../components/header/MasterHeader';
import MasterFooter from '../../components/footer/MasterFooter';
import ShareLink from './ShareLink';
import ShareCont from './ShareCont';

//assets

export default class SharePage extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { data } = this.props;

    return (
      <div className="main-share-page">
        <MasterHeader />

        <div className="main-share-container">

          <div className="main-share-content">
            <Routes>
              <Route path="/" element={<ShareLink shareLinks={data} />} />
              <Route path=":storySlug" element={<ShareView shareCont={data} />} />
            </Routes>
          </div>
        </div>

        <MasterFooter />
      </div>
    )
  }
}

function ShareView(props) {
  const { storySlug } = useParams();
  const shareCont = props.shareCont;

  return (
    <div className="shareview-div">
      {shareCont.filter((shareData) => (shareData.fields.storyLink === storySlug)).map((shareContent) => ( <ShareCont shareCont={shareContent.fields} key={shareContent.sys.id} />
        ))}
    </div>
  )
}
