import React, { Component } from 'react';
import {
  Link,
} from "react-router-dom";

//stylesheets
import './MasterHeader.css';
//components
import MasterHeaderMobile from './MasterHeaderMobile';

//assets
import LandingLogoBlk from '../../assets/logo/sis-logo-onblk.png';

export default class LandingHeader extends Component {
  constructor(){
    super();
    this.state = {
      ldgHdrScrl: false
    }
  }

  UNSAFE_componentWillMount() {

    const ldgChangeNavBackground = () => {
      if (window.scrollY >= 80) {
        this.setState({ldgHdrScrl: true});
      } else if (window.scrollY < 80 && window.scrollY === 0) {
        this.setState({ldgHdrScrl: false});
      }
    }

    window.addEventListener('scroll', ldgChangeNavBackground);

  }

  render() {
    const { ldgHdrScrl } = this.state;

    return (
      <header className={ldgHdrScrl ? 'header-scrl' : 'header'}>
        <Link className="sis-logo" to="/"><img src={LandingLogoBlk} alt="SIS Logo" /></Link>

        <nav className="nav">
          <ul className="navlinks">
            <li>
              <Link to="/">HOME</Link>
            </li>
            <li>
              <Link to="/share">SHARE</Link>
            </li>
            <li>
              <Link to="/shape">SHAPE</Link>
            </li>
            <li>
              <Link to="/showcase">SHOWCASE</Link>
            </li>
            <li>
              <Link to="/services">SERVICES</Link>
            </li>
            <li>
              <Link to="/inquire">INQUIRE</Link>
            </li>
          </ul>
        </nav>

        <div className="collab-btn">
          <Link to="/collaborate">COLLABORATE</Link>
          <div className="collab-btn-otln">
          </div>
        </div>

        <MasterHeaderMobile />

      </header>
    )
  }
}
