import React, { Component } from 'react';
import {
  Link,
} from "react-router-dom";

//stylesheets
import './CollaboratePage.css';

//components
import MasterHeader from '../../components/header/MasterHeader';
import MasterFooter from '../../components/footer/MasterFooter';

//assets


export default class CollaboratePage extends Component {
  constructor() {
    super();

    this.state = {
      fullName: "",
      collabEmail: "",
      collabContact: "",
      collabMessage: "",
    }
  }

  handleChange = (e) => {
    const target = e.target;
    const value = target.type === 'checkbox' ? target.checked && target.value : target.value;
    const name = target.name;

    console.log(value);
    this.setState({
      [name]: value
    })
  }

  handleOnSubmit = (e) => {
    e.preventDefault();

    const {
      fullName,
      collabEmail,
      collabContact,
      collabMessage,
    } = this.state;

    const collabFormContent = {
      fullName,
      collabEmail,
      collabContact,
      collabMessage,
    }

    fetch('/collaborate', {
      method: 'POST',
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(collabFormContent)
    }).then((res) => {
      console.log('Form Submitted');
    }).catch((err) => {
      console.error(err);
    })

  }

  render() {
    return (
      <div className="main-collab-page">
        <MasterHeader />

        <div className="main-collab-container">
          <div className="main-collab-info">
            <p className="main-collab-ctapar">LET'S COLLABORATE!</p>
            <h1 className="main-collab-head">Of course you are here because you have questions. Growth only happens for the curious</h1>
          </div>

          <div className="main-collab-form">
            <div className="collab-form-div">
              <form className="collab-form" method="post" onSubmit={this.handleOnSubmit}>
                <div className="collab-formgroup">
                  <p className="collab-formlabel">NAME*</p>
                  <input type="text"
                  id="fullName"
                  name="fullName" value={this.state.fullName} onChange={this.handleChange} className="collab-input" />
                </div>

                <div className="collab-formgroup">
                  <p className="collab-formlabel">EMAIL ADDRESS*</p>
                  <input type="email"
                  id="collabEmail"
                  name="collabEmail" value={this.state.collabEmail} onChange={this.handleChange} className="collab-input" />
                </div>

                <div className="collab-formgroup">
                  <p className="collab-formlabel">CONTACT NUMBER*</p>
                  <input type="text"
                  id="collabContact" name="collabContact" value={this.state.collabContact} onChange={this.handleChange} className="collab-input" />
                </div>

                <div className="collab-formgroup">
                  <p className="collab-formlabel">MESSAGE*</p>
                  <textarea type="text"
                  id="collabMessage" name="collabMessage" value={this.state.collabMessage} onChange={this.handleChange} className="collab-textarea" rows="6"/>
                </div>

                <div className="collab-formsubmit">
                  <button className="collab-submit" type="submit">SUBMIT</button>
                  <div className="collab-submit-btn-otln"></div>
                </div>
              </form>
            </div>
          </div>

        </div>

        <MasterFooter />
      </div>
    )
  }
}
