import React, { Component } from 'react';
import {
  Routes,
  Route,
  Link,
  useParams
} from "react-router-dom";
import { Helmet } from 'react-helmet';

//stylesheets
import './ShapePage.css';

//components
import MasterHeader from '../../components/header/MasterHeader';
import MasterFooter from '../../components/footer/MasterFooter';
import ShapeLink from './ShapeLink';
import ShapeCont from './ShapeCont';

//assets

export default class ShapePage extends Component {
  constructor(props) {
    super(props);

  }

  render() {
    const { data } = this.props;

    return (
      <div className="main-shape-page">
        <MasterHeader />

        <div className="main-shape-container">

          <div className="main-shape-content">
            <Routes>
              <Route path="/" element={<ShapeLink shapeLinks={data} />} />
              <Route path=":storySlug" element={<ShapeView shapeCont={data} />} />
            </Routes>
          </div>
        </div>

        <MasterFooter />
      </div>
    )
  }
}

function ShapeView(props) {
  const { storySlug } = useParams();
  const shapeCont = props.shapeCont;

  return (
    <div className="shapeview-div">
      {shapeCont.filter((shapeData) => (shapeData.fields.storyLink === storySlug)).map((shapeContent) => ( <ShapeCont shapeCont={shapeContent.fields} key={shapeContent.sys.id} />
      ))}
    </div>
  )
}
